import { mapState, mapGetters } from 'vuex'
import TxButton from '@/components/lib/TxButton/TxButton.vue'
import SelectItem from '@/components/lib/SelectItem/SelectItem.vue'
import TxInlineSvg from '@/components/lib/TxInlineSvg/TxInlineSvg.vue'

import TwoColumnHeaderPartial from '../TwoColumnHeaderPartial/TwoColumnHeaderPartial.vue'

import { CachedImageModel } from 'src/models'

export default {
  components: {
    TwoColumnHeaderPartial,
    TxButton,
    SelectItem,
    TxInlineSvg
  },
  props: {
    coin: String
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      currencies: state => state.coins.currencies
    }),
    ...mapGetters({
      config: 'config/config',
      getCurrencyBySymbol: 'coins/getCurrencyBySymbol'
    }),
    currency () {
      return this.getCurrencyBySymbol(this.coin)
    },
    headerContent () {
      return {
        title: this.i18nSection('otcpageHeaderTitle'),
        description: this.i18nSection(`About${this.coin.toUpperCase()}Description`),
        subtitle: this.i18nSection('technologypagePromoSubTitle')
      }
    }
  },
  methods: {
    changeCurrency (currency) {
      this.$router.push({ name: this.$route.name, params: { coin: currency.symbol.toLowerCase() } })
    },
    getImageUrl (image) {
      const imageModel = CachedImageModel.fromServerModel(image, {
        baseUrl: this.config.publicService.imageUrl
      })
      return imageModel.url
    }
  }
}
