import { mapState, mapActions } from 'vuex'

import CoinPage from '../CoinPage/CoinPage.vue'
import NotFound from '../../NotFound/NotFound.vue'

export default {
  computed: {
    ...mapState({
      currencies: state => state.coins.currencies
    }),
    coin () {
      return this.$route.params.coin
    },
    currency () {
      return this.currencies.value
        .find(coin => {
          return coin.symbol.toLowerCase() === this.coin.toLowerCase()
        })
    },
    coinPage () {
      return this.currency
        ? CoinPage
        : NotFound
    }
  },
  methods: {
    ...mapActions({
      loadCurrencies: 'coins/loadCurrencies'
    })
  },
  async serverPrefetch () {
    await this.loadCurrencies()
  }
}
