import WithBackgroundImagePartial from '@/pages/lib/partials/WithBackgroundImagePartial/WithBackgroundImagePartial.vue'

export default {
  props: {
    coin: Object
  },
  components: {
    WithBackgroundImagePartial
  },
  computed: {
    symbol () {
      return this.coin.symbol.toLowerCase()
    },
    items () {
      return [
        {
          itemTitle: this.i18nTemplate('currencypageAboutCurrencyTitle', { value: this.coin.name }),
          itemText: this.i18nSection(`About${this.coin.symbol.toUpperCase()}ExtendedDescription`),
          titleAsH2: true
        }
      ]
    }
  }
}
