import { mapActions, mapGetters, mapState } from 'vuex'
import NavBar from '@/pages/lib/sections/NavBar/NavBar.vue'
import PublicFooter from '@/pages/lib/sections/PublicFooter/PublicFooter.vue'
import TxButton from '@/components/lib/TxButton/TxButton.vue'

import MarketsPlaceholderSection from '@/pages/lib/sections/MarketsPlaceholderSection/MarketsPlaceholderSection.vue'

import HeaderSection from './HeaderSection/HeaderSection.vue'
import AboutCurrencySection from './AboutCurrencySection/AboutCurrencySection.vue'
import CurrentPriceSection from '../../CoinsPages/CoinPage/CurrentPriceSection/CurrentPriceSection.vue'
import SeoMarketsSection from '../../CoinsPages/CoinPage/SeoMarketsSection/SeoMarketsSection.vue'
// import SeoMarketsSection from './SeoMarketsSection/SeoMarketsSection.vue'

export default {
  components: {
    NavBar,
    PublicFooter,
    TxButton,
    MarketsPlaceholderSection,
    SeoMarketsSection,
    HeaderSection,
    AboutCurrencySection,
    CurrentPriceSection
  },
  computed: {
    ...mapGetters({
      config: 'config/config',
      getCurrencyBySymbol: 'coins/getCurrencyBySymbol'
    }),
    ...mapState({
      currencies: state => state.coins.currencies.value,
      markets: state => state.coins.markets,
      currentMarket: state => state.coins.currentMarket
    }),
    coinSymbol () {
      return this.$route.params.coin
    },
    currentCurrency () {
      return this.getCurrencyBySymbol(this.coinSymbol)
    }
  },
  watch: {
    async coinSymbol () {
      await this.setDefaultMarket({ coin: this.coinSymbol })
      this.loadInitialRate({ rateServiceSymbol: this.currentCurrency.rateServiceSymbol })
    },
    async currentMarket () {
      await this.loadTradeData({ market: this.currentMarket })
    }

  },
  methods: {
    ...mapActions({
      loadInitialData: 'coins/loadInitialData',
      setDefaultMarket: 'coins/setDefaultMarket',
      loadTradeData: 'coins/loadTradeData',
      loadInitialRate: 'coins/loadInitialRate'
    }),
    createLocalizedLink (url) {
      if (url.startsWith('/private')) {
        return url
      }
      return this.getLanguageUrlPathPrefix() + url
    }
  },
  async serverPrefetch () {
    await this.loadInitialData({
      rateServiceSymbol: this.currentCurrency.rateServiceSymbol
    })
  }
}
