import { render, staticRenderFns } from "./AboutCurrencySection.htm?vue&type=template&id=15e64198&scoped=true&"
import script from "./AboutCurrencySection.js?vue&type=script&lang=js&"
export * from "./AboutCurrencySection.js?vue&type=script&lang=js&"
import style0 from "./AboutCurrencySection.scss?vue&type=style&index=0&id=15e64198&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15e64198",
  null
  
)

export default component.exports